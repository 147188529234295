.hm-App {
  .hm-orcalab {
    color: rgba(#33bbaa, 1);
    i &:after {
      border-color: rgba(#33bbaa, 1);
    }
  }

  .hm-simres {
    color: rgba(#3377dd, 1);
  }

  .hm-gitgaat-first-nation-territory {
    color: rgba(#dd7766, 1);
  }

  .hm-kitasooxaixias-first-nation-territory {
    color: rgba(#bb66aa, 1);
  }

  .hm-heiltsuk-first-nation-territory {
    color: rgba(#66bb66, 1);
  }
}

.hm-Button {
  cursor: pointer;
  background-color: white;
  border: solid black 1px;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  &:hover {
    background-color: #f9f9f9;
    border-color: #999;
  }
  &.hm-Close {
    font-size: 1.5rem;
    border: solid 1px black;
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    padding: 0;
    .hm-CloseIcon {
      line-height: 1.85rem;
      margin: auto;
      width: fit-content;
      height: fit-content;
    }
  }
}
