.hm-MapContainer .mapboxgl-popup-content {
  padding: 1rem;
  border-radius: 0px;
  // box-shadow: 0px 5px 2px 5px #888888;
  .mapboxgl-popup-close-button {
    font-size: 1.5rem;
  }
  background-color: rgba(255, 255, 255, 0.9);
  border: solid rgba(255, 255, 255, 0.9) 2px;
  width: 400px;
}

.hm-HydrophonePopup {
  h3 {
    margin-top: 0px;
    font-size: 1.5rem;
    margin-bottom: 10px !important;
  }
  p {
    margin: 0px;
    padding: 0px;
  }
  .hm-Label {
    color: #666;
    margin-right: 3px;
  }
  .hm-Value {
    font-weight: 500;
  }
  .hm-Separator {
    margin: 0px 5px;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 10px !important;
    font-size: 1.2rem;
    color: #666;
  }
  .hm-Readings {
    z-index: 1000;
    // height: 200px;
    background-color: #f9f9f9;
    border: solid rgba(200, 200, 200, 0.8) 1px;
    padding: 10px;
  }
  .hm-FullReadings {
    min-height: 200px;
    background-color: #f9f9f9;
    border: solid rgba(200, 200, 200, 0.8) 1px;
    padding: 10px;
  }
  p.hm-LinkOut {
    margin-top: 5px;
    text-align: right;
    font-size: 0.9rem;
  }
  .hm-Details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
