.mapboxgl-marker {
  position: absolute;
  z-index: 300;

  &.OnTop {
    z-index: 400;
  }
}

.hm-Marker {
  // z-index: 450;
  // cursor: pointer;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hm-GroupedMarker {
  background-color: rgba(255, 255, 255, 1);

  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: solid black 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  i {
    font-size: 30px;
    font-weight: normal;
  }
}

.hm-SingleMarker {
  padding: 0;
  margin: 0;
  position: relative;
  font-size: 0.9rem;
  i {
    font-weight: normal;
  }
  .hm-StationLabel {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
  }
  h1 {
    margin-top: 0;
    text-align: center;
    font-weight: bold !important;
    font-size: 1.2rem !important;
    text-shadow: white 0px 0px 4px;
    margin-bottom: 0.25rem;
  }
  i.hm-LabelIcon {
    font-size: 1.4rem;
    height: 40px;
    width: 40px;
    border: solid 1px black;
    border-radius: 50%;
    text-align: center;
    line-height: 180%;
    background-color: rgba(255, 255, 255, 0.8);
  }
  &.hm-ExpandedMarker {
    i.hm-LabelIcon {
      background-color: rgba(255, 255, 255, 1);
    }
  }
  .hm-ExpandedArea {
    margin-top: -22px;

    background-color: rgba(255, 255, 255, 1);
    padding: 1rem;
    padding-top: 1.5rem;
    border: solid black 1px;
    border-radius: 1rem;
  }
}
