.hm-Legend {
  position: absolute;
  z-index: 500;

  &.hm-LowZIndex {
    z-index: 100;
    opacity: 0.2;
  }

  left: 1rem * 2;
  top: 1rem * 2;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 0.5rem;
  border: solid 1px #666;
  border-radius: 0.5rem;

  h2 {
    margin: 0px;
    padding: 0px;
    font-size: 1.2rem;
  }

  h3 {
    margin: 0.2rem 0rem;
    font-size: 1rem;
  }

  .hm-Region {
    cursor: pointer;
    &:hover {
      background-color: #eee;
    }
  }

  h4 {
    margin: 0rem;
  }

  h5 {
    margin: 0rem;
  }

  // transition: width 0.2s, height 0.2s;

  .hm-Flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .hm-Menu {
    .hm-Hamburger {
      div {
        width: 2rem;
        height: 0.25rem;
        background-color: #666;
        margin: 0.25rem 0;
      }
    }
    .hm-SelectBox {
      display: none;
      font-weight: bold;
      text-align: center;
      .hm-DownArrow {
        color: #555;
        line-height: 1rem;
        font-size: 0.9rem;
        margin-left: 0.5rem;
      }
    }
  }
}

.hm-Legend.hm-Collapsed {
  cursor: pointer;
}

.hm-Legend.hm-Expanded {
  width: 16rem;
  // height: 42rem;
}
.hm-LegendHydrophone {
  cursor: pointer;
  list-style-type: none;
  font-weight: normal;
  font-size: 0.8rem;
  padding: 1px;

  i.fas {
    margin-right: 0.4rem;
  }

  &:hover {
    opacity: 0.8;
    background-color: #eee;
    .hm-Location {
      opacity: 1;
    }
  }

  .hm-Location {
    font-size: 0.7rem;
    color: #777;
    font-weight: 300;
    opacity: 0;
  }
}

@media only screen and (max-width: 676px) {
  .hm-Legend {
    left: 0;
    right: 0;
    margin: auto;
    width: 16rem;
    .hm-Territory {
      display: none;
    }
    .hm-Menu {
      .hm-SelectBox {
        display: block;
      }
      .hm-Hamburger {
        display: none;
      }
    }
  }
}
